import ApexChart from 'react-apexcharts'
import {daysInReverse, getDayName} from 'helpers/dates'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'

export const AlertCountsChart = ({query, days = 10, width, height = 180}) => {
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])

  const fetchAlertsview = useFetchGet('alertsview')

  const getAlertsview = () => {
    const params = Params({...query, start: `day-${days}`, count: 'day'})

    fetchAlertsview(params, response => {
      const result = response.alertsview || []

      const today = moment().startOf('day') // Local timezone, start of the day
      const startDate = moment()
        .subtract(days - 1, 'days')
        .startOf('day')

      console.log('Start date:', startDate.format('YYYY-MM-DD HH:mm:ss'))
      console.log('Today date:', today.format('YYYY-MM-DD HH:mm:ss'))

      const dateMap = new Map()

      // Initialize all dates in range with zero values
      for (let d = moment(startDate); d.isSameOrBefore(today, 'day'); d.add(1, 'day')) {
        const isoDate = d.format('YYYY-MM-DD') // Local YYYY-MM-DD format
        const dayOfWeek = getDayName(d.toDate())
        dateMap.set(isoDate, {x: dayOfWeek, yWarning: 0, yCritical: 0})
      }

      // Log initialized dateMap
      console.log('Initialized dateMap:', Array.from(dateMap.entries()))

      // Populate existing data into the map
      result.forEach(entry => {
        const isoDate = entry.interval.split('T')[0] // Extract YYYY-MM-DD without using moment

        console.log('Processing entry:', entry.interval, '->', isoDate)

        if (dateMap.has(isoDate)) {
          const existing = dateMap.get(isoDate)
          dateMap.set(isoDate, {
            x: existing.x,
            yWarning: entry.temp_warning || 0,
            yCritical: entry.temp_critical || 0
          })
        }
      })

      // Log final values in dateMap
      console.log('Final dateMap:', Array.from(dateMap.entries()))

      // Convert map to sorted array
      const warnings = []
      const criticals = []

      dateMap.forEach(value => {
        warnings.push({x: value.x, y: value.yWarning})
        criticals.push({x: value.x, y: value.yCritical})
      })

      setSeries([
        {name: 'Warning', data: warnings},
        {name: 'Critical', data: criticals}
      ])
    })
  }

  useEffect(() => {
    if (query) getAlertsview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    let dayLabels = daysInReverse(days)
    dayLabels[0] = 'Today'
    dayLabels.reverse()

    const colors = ['#FFCC00', '#FF0000']

    setOptions({
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: colors,
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth',
        width: 1,
        colors: colors
      },
      title: {
        text: 'Alerts',
        align: 'left',
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: 'black'
        }
      },
      xaxis: {
        categories: dayLabels,
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: 'black'
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: 'black'
          }
        }
      },
      markers: {
        size: 1,
        colors: colors,
        strokeWidth: 2
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black'
      }
    })
  }, [days])

  return <ApexChart options={options} series={series} type="line" height={height} width={width * 0.9} />
}
