import moment from 'moment'

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const getDayName = date => {
  const dayIndex = date.getDay()
  return dayNames[dayIndex]
}
export const daysInReverse = daysBack => {
  const dayAbbreviations = []
  const today = new Date()

  for (let i = 0; i < daysBack; i++) {
    const day = new Date(today)
    day.setDate(today.getDate() - i)
    dayAbbreviations.push(day.toLocaleString('en-US', {weekday: 'short'}))
  }

  return dayAbbreviations
}

// Format the date portion (YYYY-MM-dd) using toLocaleString
export const formatDate = timestamp => {
  return timestamp
    ? new Date(timestamp).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    : ''
}

export const formatDateTime = timestamp => {
  return timestamp ? moment(timestamp).local().format('M/D/YYYY HH:mm') : ''
}
