import {Button, Table} from 'antd'
import {useEffect, useState} from 'react'
import moment from 'moment'

const getRowKey = record => {
  return `${record?.id}`
}

const getColumns = handleItemClick => [
  {
    title: 'Name',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handleItemClick(record)
        }}
      >
        {record.name}
      </Button>
    )
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (text, row) => {
      return moment(row.created).local().format('yyyy/MM/DD')
    }
  }
]

const ItemsTable = ({items, pagination, handleItemClick, rowKeyFunc = getRowKey, columnsFunc = getColumns}) => {
  const [columns, setColumns] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    setColumns(columnsFunc(handleItemClick))
  }, [columnsFunc, handleItemClick])

  useEffect(() => {
    if (items) setData(items)
  }, [items])

  return (
    <Table
      data-testid="items-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKeyFunc(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default ItemsTable
