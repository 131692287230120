import React from 'react'
import {Card} from 'antd'

const FHCard = ({title, children, height = '300px'}) => {
  return (
    <Card
      title={title}
      className="fixed-height-card" // Apply the fixed height class
      style={{height}} // Set the height dynamically
    >
      {children} {/* Render children inside the card */}
    </Card>
  )
}

export default FHCard
