import React from 'react'
import ApexChart from 'react-apexcharts'

const SpeedometerGauge = ({range, label, value, gradientColors = ['#ff0000']}) => {
  // Calculate the percentage of the value within the range
  const percentage = ((value - range.min) / (range.max - range.min)) * 100

  // Calculate the rotation angle for the needle (arm)
  const needleRotation = (percentage / 100) * 180 - 90 // Convert percentage to degrees (-90 to 90)

  // Chart configuration
  const chartOptions = {
    chart: {
      type: 'radialBar',
      height: 350
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: '65%' // Adjust the size of the hollow center
        },
        track: {
          background: '#f0f0f0' // Background color of the track
        },
        dataLabels: {
          name: {
            fontSize: '14px',
            color: '#333',
            offsetY: 20 // Adjust the position of the label
          },
          value: {
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#333',
            offsetY: -10, // Adjust the position of the value
            formatter: val => `${value}` // Display the actual value
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: gradientColors, // Gradient color for the gauge
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round' // Rounded edges for the bar
    },
    labels: [label] // Label for the gauge
  }

  // Chart data series
  const chartSeries = [percentage] // Percentage value for the gauge

  return <ApexChart options={chartOptions} series={chartSeries} type="radialBar" height={180} />
}

export default SpeedometerGauge
