import {Collapse} from 'antd'
import {useState} from 'react'
import {TargetsviewTableCard} from '../targetsview-table-card'
import GridRow from '@components/grid-row'
import {widthLarge} from 'helpers/style'
import {useNavigate} from 'react-router-dom'

const {Panel} = Collapse

const SiteTargets = ({sitesview}) => {
  const [activeKey, setActiveKey] = useState(1) // Controls which panel is open

  const navigate = useNavigate()
  const handleTargetClick = target => {
    if (target?.id) {
      navigate(`/target/${target.id}`)
    }
  }

  const handleChange = key => {
    // Toggle the panel: if the same key is clicked again, close it
    setActiveKey(activeKey === key ? null : key)
  }

  if (!sitesview) return <></>

  return (
    <Collapse activeKey={activeKey} onChange={handleChange}>
      <Panel header="Targets" key="1">
        <GridRow layout={[24]}>
          <TargetsviewTableCard
            query={{s_id: sitesview.s_id}}
            width={widthLarge}
            handleTargetClick={handleTargetClick}
            parentLevel={2}
          />
        </GridRow>
      </Panel>
    </Collapse>
  )
}

export default SiteTargets
