import AlertsviewTable from '@containers/main/alerts/alertsview-table'
import {Collapse} from 'antd'
import {useState} from 'react'

const {Panel} = Collapse

const SiteAlerts = ({sitesview}) => {
  const [activeKey, setActiveKey] = useState(null) // Controls which panel is open

  const handleChange = key => {
    // Toggle the panel: if the same key is clicked again, close it
    setActiveKey(activeKey === key ? null : key)
  }

  return (
    <Collapse activeKey={activeKey} onChange={handleChange}>
      <Panel header="Alerts" key="1">
        <AlertsviewTable query={{g_id: sitesview.g_id}} excludeItemInfo={false} showFilter={false} showLimit={false} />
      </Panel>
    </Collapse>
  )
}

export default SiteAlerts
