import {Collapse} from 'antd'
import CameraDetails from '@components/camera-details'
import GatewayDetails from '@components/gateway-details'
import SiteDetails from '@components/site-details'
import TargetDetails from '@components/target-details'
import TenantDetails from '@components/tenant-details'
import {useState} from 'react'
import {deconstruct} from 'helpers/results'

const {Panel} = Collapse

const Details = ({targetsview}) => {
  const [activeKey, setActiveKey] = useState(null) // Controls which panel is open

  const handleChange = key => {
    // Toggle the panel: if the same key is clicked again, close it
    setActiveKey(activeKey === key ? null : key)
  }

  const {tenant, site, gateway, camera, target} = deconstruct(targetsview)

  return (
    <Collapse activeKey={activeKey} onChange={handleChange}>
      <Panel header="Details" key="1">
        <TenantDetails tenant={tenant} />
        <SiteDetails site={site} />
        <GatewayDetails gateway={gateway} />
        <CameraDetails camera={camera} />
        <TargetDetails target={target} />
      </Panel>
    </Collapse>
  )
}

export default Details
