import {colors} from 'helpers/style'
import _ from 'lodash'
import moment from 'moment'
import {useEffect} from 'react'
import {useState} from 'react'
import ApexCharts from 'react-apexcharts'
import {aggregateTemperatures} from 'helpers/temperatures'

function formatTimestamp(timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment.utc(timestamp).format(format)
}
function selectedTimeRange(temperatures) {
  const format = 'YYYYMMDDTHHmmss'
  const beginTime = formatTimestamp(temperatures[0].timestamp, format)
  const endTime = formatTimestamp(temperatures.slice(-1).pop().timestamp, format)
  if (beginTime.split('T')[0] == endTime.split('T')[0]) {
    return `${beginTime}-${endTime.split('T')[1]}`
  } else {
    return `${beginTime}-${endTime}`
  }
}
const TemperatureChart = ({targetsview, temperatures, setTemperature}) => {
  const [data, setData] = useState(null)

  const handleClickOnDataPoint = index => {
    if (index >= 0) {
      setTemperature(temperatures[index])
    }
  }

  const setup = () => {
    const extractTemperatures = (temperatures, accessor, transformer = val => val) =>
      _.map(temperatures, temp => {
        const value = accessor(temp)
        return value !== undefined ? transformer(value) : null
      })

    const ambient = extractTemperatures(
      temperatures,
      temp => temp.ambient,
      val => val * 1
    )
    const max = extractTemperatures(temperatures, temp => temp.max)
    const min = extractTemperatures(temperatures, temp => temp.min)
    const mean = extractTemperatures(temperatures, temp => temp.mean)

    const categories = _.map(temperatures, temp => moment(temp.timestamp).local().toISOString())

    const allTemperatures = [
      ...ambient,
      ...max,
      ...min,
      ...mean,
      targetsview.tg_warning,
      targetsview.tg_critical
    ].filter(Boolean)

    const [yaxisMin, yaxisMax] = [
      Math.floor((Math.min(...allTemperatures) - 5) / 5) * 5,
      Math.ceil((Math.max(...allTemperatures) + 5) / 5) * 5
    ]

    const series = {
      data: [
        {
          name: 'Maximum',
          data: max
        },
        {
          name: 'Mean',
          data: mean
        },
        {
          name: 'Minimum',
          data: min
        }
      ],
      colors: [colors.chart.maximum, colors.chart.mean, colors.chart.minimum],
      fillColors: ['transparent', 'transparent', 'transparent']
    }

    if (ambient && ambient.length > 0 && ambient[0]) {
      series.data.push({
        name: 'Ambient',
        data: ambient
      })
      series.colors.push(colors.chart.ambient)
      series.fillColors.push('transparent')
    }

    setData({
      series: series.data,
      options: {
        chart: {
          type: 'area',
          events: {
            click(event, chartContext, config) {
              handleClickOnDataPoint(config.dataPointIndex)
            }
          },
          selection: {
            enabled: true
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: []
            },
            export: {
              csv: {
                filename: `${targetsview.tg_name} ${selectedTimeRange(temperatures)}`,
                headerCategory: 'Timestamp UTC',
                dateFormatter: formatTimestamp
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: series.colors,
        stroke: {
          curve: 'smooth',
          width: 4,
          dashArray: 4
        },
        fill: {
          type: 'solid',
          opacity: 0.3,
          colors: series.fillColors
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'MM/dd',
              hour: 'hh:mmTT'
            }
          },
          show: true
        },
        yaxis: {
          forceNiceScale: false,
          show: true,
          tickAmount: (yaxisMax - yaxisMin) / 5,
          min: yaxisMin,
          max: yaxisMax,
          labels: {
            formatter: value => {
              return Math.round(value) // No decimal places for y-axis labels
            }
          }
        },
        tooltip: {
          x: {
            format: 'yyyy/MM/dd HH:mm'
          },
          y: {
            formatter: value => {
              return value.toFixed(2) // 2 decimal places in tooltip
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: targetsview.tg_warning,
              borderColor: colors.blue40,
              fillColor: colors.blue40,
              strokeDashArray: 10,
              opacity: 0.3,
              label: {
                borderColor: colors.chart.warning,
                style: {
                  color: '#fff',
                  background: colors.chart.warning
                },
                text: 'Warning',
                offsetX: 0,
                textAnchor: 'end',
                offsetY: 0
              }
            },
            {
              y: targetsview.tg_critical,
              borderColor: colors.blue40,
              fillColor: colors.blue40,
              strokeDashArray: 10,
              opacity: 0.3,
              label: {
                borderColor: colors.chart.critical,
                style: {
                  color: '#fff',
                  background: colors.chart.critical
                },
                text: 'Critical',
                textAnchor: 'end',
                offsetX: 0,
                offsetY: 0
              }
            }
          ]
        }
      }
    })
  }

  useEffect(() => {
    if (temperatures) setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temperatures])

  if (data) {
    return (
      <div id="temperature-chart" style={{height: '500px'}}>
        <ApexCharts options={data.options} series={data.series} type="area" height={500} />
      </div>
    )
  } else {
    return <div id="temperature-chart" style={{height: '500px'}}></div>
  }
}

export default TemperatureChart
