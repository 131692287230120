import {Card} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'
import RailGWStatusTable from './railgwstatus-table'
import PageHeader from '@components/page-header'
import {paginationDefaults} from 'helpers/style'

export const RailGWStatusTableCard = ({query, width}) => {
  const [meta, setMeta] = useState()
  const [data, setData] = useState()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(100)

  const fetchRailgwstatusview = useFetchGet('railgwstatus')

  const getRailGWStatus = () => {
    const params = Params({...query, limit: 1000})
    fetchRailgwstatusview(params, response => {
      setMeta(response.meta)
      setData(response.railgwstatus)
    })
  }

  useEffect(() => {
    if (query?.gid) getRailGWStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (!data) return <></>

  return (
    <Card className={`w-${width}`}>
      <PageHeader ghost={false} title="Rail Gateway Status Events" subTitle="" backIcon={false} />
      <RailGWStatusTable
        railgwstatus={data}
        pagination={paginationDefaults(meta.total, limit, page, setPage, setLimit)}
      />
    </Card>
  )
}
