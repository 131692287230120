import {Card, Row, Col} from 'antd'
import LocationsMap from './locations-map'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'
import {useTenantsSettings} from '@store/settings'
import {paginationDefaults, widthSmall} from 'helpers/style'
import PageHeader from './page-header'
import {DELTA_THERMAL_GLOBAL_HQ, MINZOOM, toLocations} from 'helpers/locations'
import {extractTenants} from 'helpers/results'
import TargetStatusLegend from './target-status-legend'
import TargetStatusTable from './target-status-table'
import ListFilter from './list-filter'

const TenantsMapRow = ({handleTenantClick}) => {
  const [tenants, setTenants] = useState()
  const [tenantsMeta, setTenantsMeta] = useState()
  const fetchTenantsview = useFetchGet('tenantsview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useTenantsSettings()
  const [locations, setLocations] = useState()
  const [minZoom, setMinZoom] = useState(MINZOOM.SITES)

  const getTenants = () => {
    const params = Params({orderBy: 't_name', order: 'ASC', filter, limit, page})
    fetchTenantsview(params, response => {
      const tenants = extractTenants(response.tenantsview)
      setTenants(tenants)
      setTenantsMeta(response.meta)
      onTenantsChange(tenants)
    })
  }

  useEffect(() => {
    getTenants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, limit])

  const onTenantsChange = tenants => {
    if (tenants && tenants.length > 0) {
      const items = tenants.reduce(
        (acc, tenant) => {
          if (tenant.latitude && tenant.longitude) {
            const item = {
              id: tenant.id,
              name: tenant.name,
              latitude: tenant.latitude,
              longitude: tenant.longitude
            }
            acc.push(item)
          }
          return acc
        },
        [DELTA_THERMAL_GLOBAL_HQ]
      )
      setLocations(toLocations(items))
    } else {
      // Show DT Headquarters location; leave off id to make not clickable
      setLocations(toLocations([DELTA_THERMAL_GLOBAL_HQ]))
      setMinZoom(MINZOOM.SITES)
    }
  }

  // Show loading while fetching tenants
  if (!tenants) return <p>Loading data...</p>

  return (
    <Row gutter={16} style={{margin: '15px'}}>
      {/* Tenants Card - takes 1/4 width */}
      <Col xs={24} md={8}>
        <Card className={`w-${widthSmall}`}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Companies"
            subTitle=""
            backIcon={false}
            extra={[
              <ListFilter
                key="list-filter"
                onFilter={value => {
                  setFilter(value)
                  setPage(1)
                }}
                filter={filter}
              />
            ]}
          />
          <TargetStatusLegend mode={'horizontal'} />
          <TargetStatusTable
            items={tenants}
            handleItemClick={handleTenantClick}
            pagination={paginationDefaults(tenantsMeta?.total, limit, tenantsMeta?.page, setPage, setLimit)}
          />
        </Card>
      </Col>

      {/* Locations Map Card - takes 3/4 width */}
      <Col xs={24} md={16}>
        <Card className="max-w-full">
          <div style={{height: '77vh', width: '61vw'}}>
            <LocationsMap
              locations={locations}
              onLocationClick={location => handleTenantClick(location)}
              minZoom={minZoom}
            />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default TenantsMapRow
