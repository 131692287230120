import {useEffect, useState} from 'react'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {capitalizeFirstLetter, widthLarge} from 'helpers/style'
import GridRow from '@components/grid-row'
import {RailGWStatusTableCard} from './railgwstatus-table-card'
import GatewayAlerts from './gateway-alerts'
import {ErrorState} from '@components/error-state'
import {LoadingState} from '@components/loading-state'
import GatewayTargets from './gateway-targets'
import {useParams} from 'react-router-dom'
import {Wrapper} from '../styles'
import MetricsCards from '../metrics-cards'

function getTitle(gatewaysview) {
  if (!gatewaysview) return ''
  return (
    <>
      <span className="text-gray-500">Site:</span> {gatewaysview.s_name} <span className="text-gray-500">Gateway:</span>{' '}
      {gatewaysview.g_name}
    </>
  )
}

function getExtra(gatewaysview) {
  if (!gatewaysview) return ''
  return (
    <>
      Type: {capitalizeFirstLetter(gatewaysview.g_type)}{' '}
      {gatewaysview.g_macid ? ` MAC ID: ${gatewaysview.g_macid}` : ''}
      {gatewaysview.g_imei ? ` IMEI: ${gatewaysview.g_imei}` : ''}
    </>
  )
}

const GatewayDashboard = () => {
  const {gid} = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [gatewaysview, setGatewaysview] = useState(null)
  const fetchGatewaysView = useFetchGet('gatewaysview')

  const fetchGatewayData = async () => {
    try {
      setIsLoading(true)
      setError(null)

      const params = Params({g_id: gid, limit: 1})
      await fetchGatewaysView(params, response => {
        if (!response?.gatewaysview?.length) {
          throw new Error('Gateway not found')
        }
        setGatewaysview(response.gatewaysview[0])
      })
    } catch (err) {
      setError(err.message || 'Failed to fetch gateway data')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (gid) {
      fetchGatewayData()
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [gid])

  if (isLoading) {
    return <LoadingState />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!gatewaysview) {
    return null
  }

  return (
    <main className="min-h-screen bg-gray-50">
      <div style={{padding: '0 1rem'}}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={getTitle(gatewaysview)}
          extra={getExtra(gatewaysview)}
        />
      </div>

      <MetricsCards query={{g_id: gatewaysview.g_id}} />

      <GatewayTargets gatewaysview={gatewaysview} />

      {gatewaysview.g_type === 'rail' && (
        <GridRow layout={[24]}>
          <RailGWStatusTableCard query={{gid: gatewaysview.g_id}} width={widthLarge} />
        </GridRow>
      )}

      <GatewayAlerts gatewaysview={gatewaysview} />
    </main>
  )
}

export default GatewayDashboard
