import {TargetStatusColors} from 'helpers/labels'

export const TargetStatusLegend = ({mode}) => {
  if (mode === 'stacked') {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
          height: '100%',
          margin: 0, // Ensure no margin is applied to the grid container
          padding: 0 // Ensure no padding is applied to the grid container
        }}
      >
        {/* Top Line */}
        <div style={{display: 'flex', justifyContent: 'space-between', height: '100%', margin: 0, padding: 0}}>
          {[
            {color: TargetStatusColors[0], label: 'New'},
            {color: TargetStatusColors[1], label: 'Offline'},
            {color: TargetStatusColors[2], label: 'Online'}
          ].map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '33%',
                height: '100%',
                backgroundColor: item.color,
                color: '#fff',
                fontSize: '50%',
                borderRadius: 0,
                margin: 0, // Ensure no margin between blocks
                padding: 0 // Ensure no padding in the blocks
              }}
            >
              {item.label}
            </div>
          ))}
        </div>

        {/* Middle Line */}
        <div style={{display: 'flex', justifyContent: 'center', height: '100%', margin: 0, padding: 0}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: TargetStatusColors[3],
              color: '#fff',
              fontSize: '50%',
              borderRadius: 0,
              margin: 0, // Ensure no margin
              padding: 0 // Ensure no padding
            }}
          >
            Normal
          </div>
        </div>

        {/* Bottom Line */}
        <div style={{display: 'flex', justifyContent: 'space-between', height: '100%', margin: 0, padding: 0}}>
          {[
            {color: TargetStatusColors[4], label: 'Warning'},
            {color: TargetStatusColors[5], label: 'Critical'},
            {color: TargetStatusColors[6], label: 'Error'}
          ].map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '33%',
                height: '100%',
                backgroundColor: item.color,
                color: '#fff',
                fontSize: '50%',
                borderRadius: 0,
                margin: 0, // Ensure no margin
                padding: 0 // Ensure no padding
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    )
  } else if (mode === 'horizontal') {
    return (
      <div
        style={{
          display: 'flex', // Change to flex for horizontal alignment
          width: '100%', // Full width container
          height: '100%', // Full height container
          margin: 0, // Ensure no margin is applied
          padding: '.5rem 0',
          backgroundColor: 'transparent' // Optional background for visualization
        }}
      >
        {[
          {color: TargetStatusColors[0], label: 'New'},
          {color: TargetStatusColors[1], label: 'Offline'},
          {color: TargetStatusColors[2], label: 'Online'},
          {color: TargetStatusColors[3], label: 'Normal'},
          {color: TargetStatusColors[4], label: 'Warning'},
          {color: TargetStatusColors[5], label: 'Critical'},
          {color: TargetStatusColors[6], label: 'Error'}
        ].map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '14.28%', // Distribute equally (100% / 7 items)
              height: '100%', // Full height of the container
              backgroundColor: item.color,
              color: '#fff',
              fontSize: '75%', // Adjust font size
              borderRadius: 0,
              margin: 0, // Ensure no margin
              padding: 0 // Ensure no padding
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    )
  }
}

export default TargetStatusLegend
