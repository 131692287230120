import {Button, Table} from 'antd'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {ALERT_TYPES, TARGET_STATUS} from 'helpers/labels'
import CheckIcon from '@ant-design/icons/CheckCircleTwoTone'
import CloseIcon from '@ant-design/icons/CloseCircleTwoTone'
import {formatDateTime} from 'helpers/dates'

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    white-space: pre-line;
    text-align: center;
  }
`
const getRowKey = record => {
  return `${record?.id}`
}

const getColumns = (handleTargetClick, parentLevel) => [
  {title: 'Site', dataIndex: 's_name', key: 's_name', align: 'left', hidden: parentLevel < 3},
  {title: 'Gateway', dataIndex: 'g_name', key: 'g_name', align: 'left', hidden: parentLevel < 2},
  {title: 'Camera', dataIndex: 'ca_name', key: 'ca_name', align: 'left', hidden: parentLevel < 1},
  {
    title: 'Target',
    dataIndex: 'tg_name',
    key: 'tg_name',
    align: 'left',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handleTargetClick({id: record.tg_id})
        }}
      >
        {record.tg_name}
      </Button>
    )
  },
  {
    title: 'Status',
    dataIndex: 'tg_status',
    key: 'tg_status',
    align: 'left',
    render: (text, row) => {
      const status = TARGET_STATUS[text]
      const label = status ? status.label : text // Use label from TARGET_STATUS or default to the raw text
      return <div className={`status ${text}`}>{label}</div>
    }
  },
  {
    title: 'Active',
    dataIndex: 'tg_active',
    key: 'tg_active',
    align: 'center',
    render: value => (value === 1 ? <CheckIcon twoToneColor="#44E493" /> : <CloseIcon twoToneColor="#ff0000" />)
  },
  {
    title: 'Last\nTemperature',
    dataIndex: 'tp_timestamp',
    key: 'tp_timestamp',
    align: 'center',
    render: value => formatDateTime(value)
  },
  {
    title: 'Max\nTemp',
    dataIndex: 'tp_max',
    key: 'tp_max',
    align: 'center'
  },
  {
    title: 'Last\nImage',
    dataIndex: 'i_timestamp',
    key: 'i_timestamp',
    align: 'center',
    render: value => formatDateTime(value)
  },
  {
    title: 'Last\nAlert',
    dataIndex: 'at_timestamp',
    key: 'at_timestamp',
    align: 'center',
    render: value => formatDateTime(value)
  },
  {
    title: 'Alert\nType',
    dataIndex: 'at_alert_type',
    key: 'at_alert_type',
    align: 'center',
    render: (text, row) => {
      return ALERT_TYPES[text] ? ALERT_TYPES[text] : text
    }
  }
]

const TargetsviewTable = ({targetsview, pagination, rowKeyFunc = getRowKey, handleTargetClick, parentLevel = 0}) => {
  const [columns, setColumns] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    setColumns(getColumns(handleTargetClick, parentLevel))
  }, [])

  useEffect(() => {
    if (targetsview) setData(targetsview)
  }, [targetsview])

  return (
    <StyledTable
      data-testid="targetsview-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKeyFunc(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default TargetsviewTable
