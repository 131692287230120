import {Card} from 'antd'

export const ErrorState = ({error}) => (
  <div className="flex h-screen items-center justify-center">
    <Card className="p-6 text-center">
      <h2 className="text-xl font-semibold text-red-600">Error</h2>
      <p className="mt-2">{error}</p>
    </Card>
  </div>
)
