import {TargetStatusColors} from 'helpers/labels'
import React from 'react'

export const StackedBars = ({data}) => {
  const colors = TargetStatusColors

  // Calculate the total sum of the data for percentage calculation
  const total = data.reduce((sum, value) => sum + value, 0)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%', // Full width container
        height: '100%', // Fixed height for the row
        backgroundColor: '#f0f0f0', // Optional background for visualization
        gap: '0px' // Space between bars
      }}
    >
      {data.map((value, index) => {
        // Skip rendering for bars with 0 value
        if (value === 0) return null

        return (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%', // Full height of the container
              width: `${(value / total) * 100}%`, // Percentage width
              backgroundColor: colors[index], // Assign color based on index
              color: '#fff',
              fontSize: '.7rem',
              paddingTop: '.2rem',
              paddingBottom: '.2rem',
              fontWeight: 'normal',
              textAlign: 'center'
            }}
          >
            {value}
          </div>
        )
      })}
    </div>
  )
}
