import {Card, Descriptions, Typography} from 'antd'
import moment from 'moment'

const {Title} = Typography

const CameraDetails = ({camera}) => (
  <Card>
    <Title level={3}>Camera</Title>
    <Descriptions
      column={1}
      bordered={true} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{fontWeight: 'bold', width: '15rem'}} // Adjust label width and style
      contentStyle={{margin: 0, padding: '0,1rem,0,2rem'}} // Remove padding and margin
    >
      <Descriptions.Item label="ID">{camera?.id}</Descriptions.Item>
      <Descriptions.Item label="Name">{camera?.name}</Descriptions.Item>
      <Descriptions.Item label="Kind">{camera?.kind}</Descriptions.Item>
      <Descriptions.Item label="Hardware Id">{camera?.hardware_id}</Descriptions.Item>
      <Descriptions.Item label="Part Number">{camera?.part_number}</Descriptions.Item>
      <Descriptions.Item label="Serial Number">{camera?.serial_number}</Descriptions.Item>
      <Descriptions.Item label="Timestamp">{moment(camera?.timestamp).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Created">{moment(camera?.created).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Updated">{moment(camera?.updated).local().toISOString()}</Descriptions.Item>
    </Descriptions>
  </Card>
)

export default CameraDetails
