import {DatePicker, Radio} from 'antd'
import moment from 'moment'
import React from 'react'
import {useState} from 'react'

const {RangePicker} = DatePicker

const ChartPeriodControls = ({setDaysToView, period, setPeriod}) => {
  const [viewOption, setViewOption] = useState('1')

  const onViewOptionChange = e => {
    const value = e.target.value
    setViewOption(value)
    if (value !== 'custom') {
      setDaysToView(value)
    }
  }

  const onCustomDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      setPeriod({
        start: moment(dateStrings[0]).local().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString(),
        end: moment(dateStrings[1])
          .add(1, 'days')
          .local()
          .set({hour: 0, minute: 0, second: 0, millisecond: 0})
          .toISOString()
      })
    }
  }

  return (
    <div id="target-controls">
      <Radio.Group
        style={{padding: '1px 1px 1px 0px', lineHeight: '2', backgroundColor: 'lightgray'}}
        value={viewOption}
        onChange={onViewOptionChange}
      >
        <Radio.Button value="1">One Day</Radio.Button>
        <Radio.Button value="2">Two Days</Radio.Button>
        <Radio.Button value="3">Three Days</Radio.Button>
        <Radio.Button value="7">One Week</Radio.Button>
        <Radio.Button value="custom">Custom</Radio.Button>
      </Radio.Group>
      {viewOption === 'custom' && (
        <div style={{textAlign: 'center', margin: '10px auto'}}>
          <RangePicker style={{border: '1px solid lightgray', lineHeight: '2'}} onChange={onCustomDateRangeChange} />
        </div>
      )}
    </div>
  )
}

export default ChartPeriodControls
