import HomeLayout from './_layouts/home'
import MainLayout from './_layouts/main'
import ForgotPassword from './auth/forgot-password'
import Login from './auth/login'
import SetPassword from './auth/set-password'
import Alerts from './main/alerts'
import ChangePassword from './main/change-password'
import Contacts from './main/contacts'
import Audit from './main/audit'
import TenantDashboard from './main/dashboard/tenant-dashboard'
import ContactUs from './main/help/contactus'
import Profile from './main/profile'
import Users from './main/users'
import {setUser} from '@store/auth'
import {beginUserSession} from '@store/index'
import {Spin} from 'antd'
import {GET} from 'helpers/api'
import {Suspense, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import GatewayDashboard from './main/dashboard/gateway-dashboard'
import SiteDashboard from './main/dashboard/site-dashboard'
import TargetDashboard from './main/dashboard/target-dashboard'
import Home from './home'
import Assets from './main/assets'

const Router = () => {
  console.log('TRACE', window.location)
  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  const isLoading = useSelector(state => token && !state.auth.user)

  useEffect(() => {
    console.log('TRACE', 'Router useEffect()', token ? 'w/token' : 'no token')
    if (token) {
      const request = GET(token)
      fetch(process.env.REACT_APP_BACKEND_URL + `/usersview/me`, request)
        .then(async res => {
          console.log('Router - users/me response = ' + JSON.stringify(res))
          return res?.status === 200 ? res.json() : {}
        })
        .then(response => {
          dispatch(beginUserSession(token, response))
        })
        .catch(err => {
          dispatch(setUser({user: {}}))
        })
    } else {
      dispatch(setUser({user: {}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isLoading])

  if (isLoading) {
    console.log('TRACE', 'isLoading')
    return <Spin size="large" className="root-spin" />
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="home" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Navigate to="" />} />
          </Route>

          <Route path="auth" element={<HomeLayout />}>
            <Route index element={<Navigate to="login" />} />
            <Route path="login" element={<Login />} />
            <Route path="expired" element={<Login expired={true} />} />
            {/* <Route path="signup" element={<Signup />} /> */}
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="set-password" element={<SetPassword />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Route>

          {/* <Route path="admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="users" />} />
          <Route path="users" element={<Users />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="*" element={<Navigate to="users" />} />
        </Route> */}

          <Route path="" element={<MainLayout />}>
            <Route index element={<Navigate to="tenantdashboard" />} />
            <Route path="tenantdashboard" element={<TenantDashboard />} />
            <Route path="site/:sid" element={<SiteDashboard />} />
            <Route path="gateway/:gid" element={<GatewayDashboard />} />
            <Route path="target/:tgid" element={<TargetDashboard />} />
            <Route path="assets" element={<Assets />} />
            <Route path="alerts" element={<Alerts />} />
            <Route path="users" element={<Users />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="audit" element={<Audit />} />
            <Route path="profile" element={<Profile />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="*" element={<Navigate to="tenantdashboard" />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
