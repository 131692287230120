/* eslint-disable default-case, no-fallthrough */
import {formFields as tenantFormFields} from 'containers/main/assets/forms/tenant-form'
import {formFields as siteFormFields} from 'containers/main/assets/forms/site-form'
import {formFields as gatewayFormFields} from 'containers/main/assets/forms/gateway-form'
import {formFields as cameraFormFields} from 'containers/main/assets/forms/camera-form'
import {formFields as targetFormFields} from 'containers/main/assets/forms/target-form'
import {formFields as userFormFields} from 'containers/main/users/editor'
import {formFields as contactFormFields} from 'containers/main/contacts/editor'
import {Row, Col, Timeline, Divider, Switch} from 'antd'
import {FieldTimeOutlined} from '@ant-design/icons'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {isObject} from 'lodash'
import './styles.css'

function row(label, value) {
  if (label != 'item') {
    const val = value && isObject(value) ? JSON.stringify(value) : value
    return (
      <Row gutter={16}>
        <Col className="label" span={6} offset={0}>
          {label}:
        </Col>
        <Col span={16}>{val}</Col>
      </Row>
    )
  }
}

function has(type, audit) {
  switch (type) {
    case 'site':
      return audit.item_type == 'gateway' || audit.item_type == 'camera' || audit.item_type == 'target'
    case 'gateway':
      return audit.item_type == 'camera' || audit.item_type == 'target'
    case 'camera':
      return audit.item_type == 'target'
    case 'target':
    default:
      return false
  }
}

const AuditTimeline = ({itemId}) => {
  const [show, setShow] = useState(false)
  const user = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.settings.tenant)
  const fetchAuditview = useFetchGet('auditview')
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    const getAudit = () => {
      const params = Params({item_id: itemId, limit: 100, page: 0, orderBy: 'created', order: 'ASC'})
      if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
      fetchAuditview(params, response => {
        setData(response.auditview.filter(event => event.event_type.endsWith('_item')))
        setMeta(response.meta)
      })
    }

    getAudit()
  }, [itemId, tenant, user])

  const formFieldsLookup = {
    tenant: tenantFormFields,
    site: siteFormFields,
    gateway: gatewayFormFields,
    camera: cameraFormFields,
    target: targetFormFields,
    user: userFormFields,
    contact: contactFormFields
  }

  function asLocalDateTime(isoDatetime) {
    const datetime = new Date(isoDatetime).toLocaleString().split(', ')
    return `on ${datetime[0]} at ${datetime[1]}`
  }

  function eventType(event_type) {
    switch (event_type) {
      case 'add_item':
        return 'Added'
      case 'edit_item':
        return 'Edited'
      case 'move_item':
        return 'Moved'
      case 'delete_item':
        return 'Deleted'
      case 'change_user_tenant':
        return 'Switch Company'
      default:
        return event_type.toUpperCase()
    }
  }
  function events(data) {
    let prev = {}
    return data.map(event => {
      const formFields = formFieldsLookup[event.item_type] || {}
      const item = Object.keys(event.data['item'])
        .filter(key => key in formFields)
        .reduce((obj, key) => {
          if (!prev?.data || event.data['item'][key] != prev.data['item'][key]) obj[key] = event.data['item'][key]
          return obj
        }, {})

      const itemRows = Object.entries(item).map(([key, value]) => row(formFields[key], value))

      const out = (
        <div>
          {eventType(event.event_type)} {asLocalDateTime(event.created)}
          {event.u_name ? ` by ${event.u_name}` : ''}
          {event.ip ? ` from ${event.ip}` : ''}
          {event.t_name !== prev.t_name && row('Company', event.t_name)}
          {event.s_name !== prev.s_name && has('site', event) && row('Site', event.s_name)}
          {event.g_name !== prev.g_name && has('gateway', event) && row('Gateway', event.g_name)}
          {event.ca_name !== prev.ca_name && has('camera', event) && row('Camera', event.ca_name)}
          {event.item_id !== prev.item_id && row('ID', event.item_id)}
          {itemRows}
        </div>
      )
      prev = event
      return out
    })
  }

  return (
    <div className="audit-timeline">
      {show && (
        <>
          <Divider></Divider>
          <Timeline>{events(data)}</Timeline>
        </>
      )}
      <Switch
        checkedChildren={<FieldTimeOutlined />}
        unCheckedChildren={<FieldTimeOutlined />}
        defaultChecked="false"
        onChange={checked => setShow(!checked)}
      />
    </div>
  )
}

export default AuditTimeline
