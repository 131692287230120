import {List, Typography} from 'antd'

const {Title} = Typography

const Address = ({title, address}) => {
  return (
    <>
      <Title level={3}>{title}</Title>
      <List bordered dataSource={address} renderItem={item => <List.Item>{item}</List.Item>} />
    </>
  )
}

export default Address
