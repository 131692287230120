import {Card, Descriptions, Typography} from 'antd'
import moment from 'moment'

const {Title} = Typography

const GatewayDetails = ({gateway}) => (
  <Card>
    <Title level={3}>Gateway</Title>
    <Descriptions
      column={1}
      bordered={true} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{fontWeight: 'bold', width: '15rem'}} // Adjust label width and style
      contentStyle={{margin: 0, padding: '0,1rem,0,2rem'}} // Remove padding and margin
    >
      <Descriptions.Item label="ID">{gateway?.id}</Descriptions.Item>
      <Descriptions.Item label="Name">{gateway?.name}</Descriptions.Item>
      {/* <Descriptions.Item label="Monitor">{gateway?.monitor ? 'Yes' : 'No'}</Descriptions.Item> */}
      <Descriptions.Item label="Timestamp">{moment(gateway?.timestamp).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Created">{moment(gateway?.created).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Updated">{moment(gateway?.updated).local().toISOString()}</Descriptions.Item>
    </Descriptions>
  </Card>
)

export default GatewayDetails
