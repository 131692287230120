import AlertsviewTable from '@containers/main/alerts/alertsview-table'
import {Collapse} from 'antd'
import {useState} from 'react'

const {Panel} = Collapse

const TargetAlerts = ({targetsview}) => {
  const [activeKey, setActiveKey] = useState(null) // Controls which panel is open

  const handleChange = key => {
    // Toggle the panel: if the same key is clicked again, close it
    setActiveKey(activeKey === key ? null : key)
  }

  return (
    <Collapse activeKey={activeKey} onChange={handleChange}>
      <Panel header="Alerts" key="1">
        <AlertsviewTable
          query={{tg_id: targetsview.tg_id}}
          excludeItemInfo={true}
          showFilter={false}
          showLimit={false}
        />
      </Panel>
    </Collapse>
  )
}

export default TargetAlerts
