import {Image, Typography} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {formatDateTime} from 'helpers/dates'
import {IMAGE_TYPE} from 'helpers/images'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'

const {Title} = Typography

const TargetImage = ({targetsview, imageType, timestamp, height, width, showTitle = false}) => {
  const [image, setImage] = useState(null)
  const fetchImage = useFetchGet('images')

  const getImage = () => {
    const params = Params({
      tid: targetsview.t_id,
      sid: targetsview.s_id,
      gid: targetsview.g_id,
      caid: targetsview.ca_id,
      tgid: targetsview.tg_id,
      end: timestamp,
      type: imageType,
      order: 'desc',
      orderBy: 'timestamp',
      limit: 1
    })
    fetchImage(params, response => {
      setImage(response?.images?.length > 0 ? response.images[0] : null)
    })
  }

  useEffect(() => {
    if (timestamp) {
      getImage()
    } else {
      setImage(null)
    }
  }, [targetsview, timestamp])

  const title = useMemo(() => {
    switch (imageType) {
      case IMAGE_TYPE.BLEND:
        return 'Thermal Blend'
      case IMAGE_TYPE.COLOR:
        return 'Color'
      case IMAGE_TYPE.COLORREF:
        return 'Color Reference'
      default:
        return ''
    }
  }, [imageType])

  if (image && image.url) {
    return (
      <span>
        {showTitle ? <Title level={3}>{title}</Title> : <></>}
        <figure className="target-temp-image">
          <Image src={image.url} height={height} width={width} />
        </figure>
        <figcaption>{formatDateTime(image.timestamp)}</figcaption>
      </span>
    )
  } else {
    return (
      <>
        {showTitle ? <Title level={3}>{title}</Title> : <></>}
        <div className="placeholder">[No image to display]</div>
      </>
    )
  }
}

export default TargetImage
