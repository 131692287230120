import TargetsviewTable from '@components/targetsview-table'
import {Card} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'

export const TargetsviewTableCard = ({query, width, handleTargetClick, parentLevel}) => {
  const [data, setData] = useState()

  const fetchTargetsview = useFetchGet('targetsview')

  const getTargetsview = () => {
    const params = Params(query)
    fetchTargetsview(params, response => {
      const result = response.targetsview
      setData(result)
    })
  }

  useEffect(() => {
    if (query?.t_id || query?.s_id || query?.g_id) getTargetsview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (!data) return <></>

  return (
    <Card className={`w-${width}`}>
      <TargetsviewTable
        targetsview={data}
        pagination={{}}
        handleTargetClick={handleTargetClick}
        parentLevel={parentLevel}
      />
    </Card>
  )
}
