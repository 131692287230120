import {Card, Col, Row} from 'antd'
import {widthSmall} from 'helpers/style'
import {AlertCountsChart} from '../alert-counts-chart'
import TargetImage from './target-image'
import {IMAGE_TYPE} from 'helpers/images'
import SpeedometerGauge from '@components/speedometer-gauge'
import TemperatureBars from './temperature-bars'
import FHCard from '@components/fhcard'
import {CARDS_GUTTER} from 'helpers/dashboards'

const DAYS_TO_SHOW = 7

export const InfoRow = ({targetsview}) =>
  targetsview ? (
    <Row gutter={CARDS_GUTTER} style={{margin: '1em'}}>
      <Col xs={24} md={5}>
        <FHCard height="320px">
          <div
            className={`font-bold`}
            style={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center' // Center vertically
            }}
          >
            <TargetImage
              targetsview={targetsview}
              imageType={IMAGE_TYPE.COLOR}
              timestamp={targetsview.i_timestamp ? targetsview.i_timestamp : new Date()}
              height={250}
            />
          </div>
        </FHCard>
      </Col>
      <Col xs={24} md={8}>
        <FHCard height="320px">
          <div className={`font-bold`}>
            <TemperatureBars targetsview={targetsview} />
          </div>
        </FHCard>
      </Col>
      <Col xs={24} md={3}>
        <FHCard height="320px">
          <div className={`font-bold`}>
            <SpeedometerGauge range={{min: 0, max: 100}} label="Humidity" value={targetsview.ext_humidity || 0} />
            <SpeedometerGauge range={{min: 0, max: 100}} label="Pressure" value={targetsview.ext_pressure || 0} />
          </div>
        </FHCard>
      </Col>
      <Col xs={24} md={8}>
        <FHCard height="320px">
          <div className={`font-bold`}>
            <AlertCountsChart query={{tg_id: targetsview?.tg_id}} days={DAYS_TO_SHOW} width={widthSmall} height={270} />
          </div>
        </FHCard>
      </Col>
    </Row>
  ) : (
    <></>
  )

export default InfoRow
