export const ALERT_TYPES = Object.freeze({
  configured: 'Configured',
  online: 'Online',
  offline: 'Offline',
  temp_normal: 'Normal °C',
  temp_warning: 'Warning °C',
  temp_critical: 'Critical °C',
  temp_severe: 'Severe °C',
  temp_error: 'Error °C',
  system: 'System Error',
  notice: 'ThermalSafe Notice'
})

export const TargetStatusColors = [
  '#4b4b4b', // None - dark gray
  '#7d7d7d', // Offline - medium gray
  '#b0b0b0', // Online - light gray
  '#44E493', // Normal - light green
  '#f4d03f', // Warning - yellow
  '#ff0000', // Critical - orange
  '#8b0000' // Error - red
]

export const TARGET_STATUS = {
  none: {label: 'New', color: TargetStatusColors[0]},
  online: {label: 'Online', color: TargetStatusColors[0]},
  offline: {label: 'Offline', color: TargetStatusColors[0]},
  normal: {label: 'Normal', color: TargetStatusColors[0]},
  warning: {label: 'Warning', color: TargetStatusColors[0]},
  critical: {label: 'Critical', color: TargetStatusColors[0]},
  error: {label: 'Error', color: TargetStatusColors[0]}
}
