import {Alert, Card, Col, Row, Space, Spin} from 'antd'
import {widthSmall} from 'helpers/style'
import TargetImage from './target-image'
import {IMAGE_TYPE} from 'helpers/images'
import TemperatureChart from './temperature-chart'
import {useEffect, useMemo, useState} from 'react'
import {CARDS_GUTTER} from 'helpers/dashboards'
import {GET} from 'helpers/api'
import {useSelector} from 'react-redux'
import moment from 'moment'
import TemperatureDetails from './temperature-details'
import ChartPeriodControls from './chart-period-controls'
import Icon from '@components/icon'
import PeriodDisplay from '@components/period-display'
import FHCard from '@components/fhcard'
import {calcInterval} from 'helpers/temperatures'

export const ChartRow = ({targetsview}) => {
  const [temperatures, setTemperatures] = useState(null)
  const [temperature, setTemperature] = useState(null)
  const [period, setPeriod] = useState()
  const token = useSelector(state => state.auth.token)

  useEffect(() => {
    if (targetsview) setDaysToView(targetsview, 1, setPeriod)
  }, [targetsview])

  useEffect(() => {
    const loadData = async interval => {
      let data = []
      let response = {meta: {page: 0}}
      do {
        response = await getTemperaturesview(interval, +response.meta.page + 1)
        data = data.concat(response.temperaturesview)
      } while (moreData(response.meta))
      // Add timestamp field to each object
      data = data.map(obj => {
        return {timestamp: obj.interval, max: obj.max_max, min: obj.min_min, ...obj}
      })
      setTemperatures(data)
      setTemperature(data[data.length - 1])
    }

    if (period) {
      loadData(calcInterval(period))
    } else {
      setTemperatures(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetsview, period])

  const periodDisplayRange = useMemo(() => {
    console.log('period', period)
    if (period?.start && period?.end) {
      const rangeStart = period?.start.split('T')[0]
      const rangeEnd = new moment(period.end).add(-1, 'days').toISOString().split('T')[0]
      return rangeStart === rangeEnd ? rangeStart : rangeStart + ' - ' + rangeEnd
    } else {
      return ''
    }
  }, [period])

  const setDaysToView = days => {
    const pastDaysToView = -(days - 1)
    const keepOffset = false
    const periodEnd = targetsview.tp_timestamp ? targetsview.tp_timestamp : moment().toISOString()
    const start = new moment(periodEnd)
      .local()
      .startOf('day')
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .add(pastDaysToView, 'days')
      .toISOString()
    const end = new moment(periodEnd)
      .local()
      .add(1, 'days')
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .toISOString(keepOffset)
    setPeriod({start, end})
  }

  const moreData = meta => {
    return meta.page * meta.pageSize < meta.total
  }

  const getTemperaturesview = (interval, page) => {
    const groupBy = `tg_id,${interval}`
    const request = GET(token)
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/temperaturesview?tg_id=${targetsview.tg_id}&start=${period.start}&end=${period.end}&count=${groupBy}&page=${page}&limit=1000`,
      request
    )
      .then(async res => {
        return res.json()
      })
      .then(response => {
        return response
      })
  }

  if (targetsview && temperatures && temperatures.length > 0) {
    return (
      <Row gutter={CARDS_GUTTER} style={{margin: '1em'}}>
        <Col xs={24} md={4}>
          <FHCard height="640px">
            <div
              className={`font-bold pb-10`}
              style={{
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center' // Center vertically
              }}
            >
              <TargetImage
                targetsview={targetsview}
                imageType={IMAGE_TYPE.BLEND}
                timestamp={temperature ? temperature.timestamp : new Date()}
                height={250}
              />
            </div>
            <TemperatureDetails temperature={temperature} />
          </FHCard>
        </Col>
        <Col xs={24} md={20}>
          <Card className={`w-${widthSmall} font-bold`}>
            <Space className="click-point" direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
              <PeriodDisplay period={period} />
              <Icon name="important" />
              Click on a data point to view image and details
            </Space>
            <TemperatureChart targetsview={targetsview} temperatures={temperatures} setTemperature={setTemperature} />
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
              <ChartPeriodControls setDaysToView={setDaysToView} period={period} setPeriod={setPeriod} />
            </Space>
          </Card>
        </Col>
      </Row>
    )
  } else if (!temperatures) {
    return (
      <div style={{minHeight: '500px'}}>
        <Spin />
      </div>
    )
  } else if (temperatures?.length === 0) {
    return (
      <Alert
        message={`There are no target temperatures for this date range [${periodDisplayRange}]`}
        type="info"
        style={{margin: '20px auto', textAlign: 'center', backgroundColor: 'var(--blue-20'}}
      />
    )
  }
}

export default ChartRow
