/* eslint-disable no-fallthrough */
import Icon from '@components/icon'
import {useAlertsSettings, useTenant} from '@store/settings'
import {Button, Card, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {paginationDefaults, widthLarge} from 'helpers/style'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import ListFilter from '@components/list-filter'
import GridRow from '@components/grid-row'
import {ALERT_TYPES} from 'helpers/labels'
import {format} from 'prettier'
import {formatDateTime} from 'helpers/dates'

const getColumns = ({tenant, setTenant, excludeItemInfo}) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant({id: record.tid, name: record.t_name})
          }}
        >
          {record.t_name}
        </Button>
      ),
      hidden: !!tenant || excludeItemInfo
    },
    {
      title: 'Alert',
      dataIndex: 'alert_type',
      key: 'alert_type',
      render: (text, row) => {
        return ALERT_TYPES[text] ? ALERT_TYPES[text] : text
      }
    },
    {
      title: 'Item Type',
      dataIndex: 'item_type',
      key: 'item_type',
      hidden: excludeItemInfo
    },
    {
      title: 'Item ID',
      dataIndex: 'item_id',
      key: 'item_id',
      hidden: excludeItemInfo
    },
    {
      title: (
        <>
          Site
          <Icon name="contains" />
          Gateway
          <Icon name="contains" />
          Camera
          <Icon name="contains" />
          Target
        </>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => {
        switch (row.item_type) {
          case 'site':
            return row.s_name
          case 'gateway':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
              </>
            )
          case 'camera':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
                <Icon name="contains" />
                {row.ca_name}
              </>
            )
          case 'target':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
                <Icon name="contains" />
                {row.ca_name}
                <Icon name="contains" />
                {row.tg_name}
              </>
            )
          default:
            return 'Unknown type'
        }
      },
      hidden: excludeItemInfo
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text, row) => {
        return formatDateTime(text)
      }
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      render: (text, row) => {
        let name = 'comm-failure'
        if (text.toUpperCase().includes('WARNING')) {
          name = 'temp-warning'
        } else if (text.toUpperCase().includes('CRITICAL')) {
          name = 'temp-critical'
        }
        return (
          <>
            <Icon name={name} />
            {' ' + text}
          </>
        )
      }
    }
  ].filter(item => !item.hidden)

const AlertsviewTable = ({query, excludeItemInfo = false, showFilter = true, showLimit = true}) => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const fetchAlertsview = useFetchGet('alertsview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useAlertsSettings()
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    const getAlerts = () => {
      const params = Params({
        ...query,
        filter: showFilter ? filter : undefined,
        limit,
        page,
        orderBy: 'timestamp',
        order: 'DESC'
      })
      if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
      fetchAlertsview(params, response => {
        setData(response.alertsview)
        setMeta(response.meta)
      })
    }

    getAlerts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, limit, tenant, user])

  const columns = useMemo(() => {
    return getColumns({tenant, setTenant, excludeItemInfo})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <GridRow layout={[24]}>
      <Card className={`w-${widthLarge}`}>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Alerts"
          subTitle=""
          backIcon={false}
          extra={
            showFilter
              ? [
                  <ListFilter
                    onFilter={value => {
                      setFilter(value)
                      setPage(1)
                    }}
                    filter={filter}
                    tenant={tenant}
                  />
                ]
              : []
          }
        />

        <Table
          columns={columns}
          dataSource={data}
          rowKey={alert => alert.tid + '_' + alert.alert_type + '_' + alert.timestamp}
          loading={!data}
          pagination={paginationDefaults(meta?.total, limit, meta?.page, setPage, showLimit ? setLimit : undefined)}
        />
      </Card>
    </GridRow>
  )
}

export default AlertsviewTable
