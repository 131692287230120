import {formatDate} from 'helpers/dates'

const PeriodDisplay = ({period}) => {
  // Subtract 24 hours from period.end
  const endDate = new Date(period.end)
  endDate.setHours(endDate.getHours() - 24)

  // Format the begin and end dates
  const beginDateFormatted = formatDate(period.start)
  const endDateFormatted = formatDate(endDate)

  // If begin and end dates are the same, return only begin date
  const displayText =
    beginDateFormatted === endDateFormatted ? beginDateFormatted : `${beginDateFormatted} to ${endDateFormatted}`

  return (
    <div>
      <p>{displayText}</p>
    </div>
  )
}

export default PeriodDisplay
