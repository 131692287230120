import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import GatewaysMapRow from '@components/gateways-map-row'
import {Wrapper} from '../styles'
import MetricsCards from '../metrics-cards'
import SiteAlerts from './site-alerts'
import {LoadingState} from '@components/loading-state'
import {ErrorState} from '@components/error-state'
import SiteTargets from './site-targets'

function getTitle(sitesview) {
  if (!sitesview) return ''
  return (
    <>
      <span className="text-gray-500">Site:</span> {sitesview.s_name}
    </>
  )
}

const SiteDashboard = () => {
  const {sid} = useParams()
  const navigate = useNavigate()
  const [sitesview, setSitesview] = useState(null)
  const fetchSitesView = useFetchGet('sitesview')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const handleGatewayClick = gateway => {
    if (gateway?.id) {
      navigate(`/gateway/${gateway?.id}`)
    }
  }

  const getSite = async () => {
    try {
      setIsLoading(true)
      setError(null)

      const params = Params({s_id: sid, limit: 1})
      await fetchSitesView(params, response => {
        if (!response?.sitesview?.length) {
          throw new Error('Site not found')
        }
        setSitesview(response.sitesview[0])
      })
    } catch (err) {
      setError(err.message || 'Failed to fetch site')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (sid) getSite()
  }, [sid])

  if (isLoading) {
    return <LoadingState />
  }

  if (error) {
    return <ErrorState error={error} />
  }
  if (!sitesview) return null

  return (
    <Wrapper>
      <div style={{padding: '0 1rem'}}>
        <PageHeader
          ghost={true}
          onBack={() => window.history.back()}
          title={getTitle(sitesview)}
          subTitle=""
          extra={[]}
        />
      </div>
      <MetricsCards query={{s_id: sitesview?.s_id}} />
      <GatewaysMapRow sitesview={sitesview} handleGatewayClick={handleGatewayClick} />
      <SiteTargets sitesview={sitesview} />
      <SiteAlerts sitesview={sitesview} />
    </Wrapper>
  )
}

export default SiteDashboard
