import {Select} from 'antd'
import {Params, useFetchGet, useFetchGetId} from 'helpers/api'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

const {Option} = Select

const TenantSelect = props => {
  const currentUser = useSelector(state => state.auth.user)
  const [name, setName] = useState('')
  const [tenants, setTenants] = useState(null)
  const fetchTenant = useFetchGetId('tenants')
  const fetchTenants = useFetchGet('tenants')

  const getTenants = () => {
    if (props.disabled) {
      fetchTenant(props.value ? props.value : currentUser.tid, response => {
        setTenants([response])
      })
    } else {
      const params = Params({name, orderBy: 'name'})
      fetchTenants(params, response => {
        if (props.exclude) {
          setTenants(response.tenants.filter(tenant => !props.exclude.includes(tenant.id)))
        } else {
          setTenants(response.tenants)
        }
      })
    }
  }

  useEffect(() => {
    getTenants()
  }, [props.value, props.disabled])

  const onSearch = value => {
    setName(value)
  }

  if (props.disabled) {
    return <span className="entity">{tenants?.find(tenant => tenant.id === props.value)?.name}</span>
  } else {
    return (
      <Select
        placeholder="Select a company"
        loading={!tenants}
        showSearch
        {...(tenants ? props : {})}
        onSearch={onSearch}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {tenants?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default TenantSelect
