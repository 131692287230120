export const aggregateTemperatures = (temperatures, intervalInMinutes) => {
  const aggregatedData = []
  let currentInterval = null
  let intervalData = []

  temperatures.forEach(entry => {
    const timestamp = new Date(entry.timestamp)
    const intervalStart = new Date(timestamp)
    intervalStart.setMinutes(Math.floor(timestamp.getMinutes() / intervalInMinutes) * intervalInMinutes, 0, 0)

    if (!currentInterval || intervalStart.getTime() !== currentInterval.getTime()) {
      if (intervalData.length > 0) {
        // Calculate average, min, and max for the previous interval
        const avgMean = intervalData.reduce((sum, e) => sum + e.mean, 0) / intervalData.length
        const min = Math.min(...intervalData.map(e => e.min))
        const max = Math.max(...intervalData.map(e => e.max))
        const avgAmbient = intervalData.reduce((sum, e) => sum + e.ext_temp, 0) / intervalData.length
        aggregatedData.push({
          timestamp: currentInterval.toISOString(),
          mean: parseFloat(avgMean.toFixed(2)), // Round to 2 decimal places
          min: parseFloat(min.toFixed(2)), // Round to 2 decimal places
          max: parseFloat(max.toFixed(2)), // Round to 2 decimal places
          ext_temp: parseFloat(avgAmbient.toFixed(2)) // Round to 2 decimal places
        })
      }
      // Start a new interval
      currentInterval = intervalStart
      intervalData = [entry]
    } else {
      intervalData.push(entry)
    }
  })

  // Add the last interval
  if (intervalData.length > 0) {
    const avgMean = intervalData.reduce((sum, e) => sum + e.mean, 0) / intervalData.length
    const min = Math.min(...intervalData.map(e => e.min))
    const max = Math.max(...intervalData.map(e => e.max))
    const avgAmbient = intervalData.reduce((sum, e) => sum + e.ext_temp, 0) / intervalData.length
    aggregatedData.push({
      timestamp: currentInterval.toISOString(),
      mean: parseFloat(avgMean.toFixed(2)), // Round to 2 decimal places
      min: parseFloat(min.toFixed(2)), // Round to 2 decimal places
      max: parseFloat(max.toFixed(2)), // Round to 2 decimal places
      ext_temp: parseFloat(avgAmbient.toFixed(2)) // Round to 2 decimal places
    })
  }

  return aggregatedData
}

export const calcInterval = period => {
  const intervals = [
    {name: '5min', value: 5},
    {name: '10min', value: 10},
    {name: '15min', value: 15},
    {name: '30min', value: 30},
    {name: 'hour', value: 60},
    {name: '3hour', value: 180},
    {name: '6hour', value: 360},
    {name: '12hour', value: 720},
    {name: 'day', value: 1440},
    {name: '7day', value: 10080},
    {name: '14day', value: 20160},
    {name: '28day', value: 40320},
    {name: 'month', value: 43200}, // Approximate (30 days)
    {name: 'year', value: 525600} // Approximate (365 days)
  ]

  const start = new Date(period.start)
  const end = new Date(period.end)
  const totalMinutes = (end - start) / (1000 * 60) // Convert milliseconds to minutes
  const maxDataPoints = 300
  let intervalInMinutes = Math.ceil(totalMinutes / maxDataPoints)

  // Find the closest interval from predefined list
  return intervals.reduce((prev, curr) =>
    Math.abs(curr.value - intervalInMinutes) < Math.abs(prev.value - intervalInMinutes) ? curr : prev
  ).name
}
