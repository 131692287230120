import {Card, Descriptions, Typography} from 'antd'
import moment from 'moment'

const {Title} = Typography

const SiteDetails = ({site}) => (
  <Card>
    <Title level={3}>Site</Title>
    <Descriptions
      column={1}
      bordered={true} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{fontWeight: 'bold', width: '15rem'}} // Adjust label width and style
      contentStyle={{margin: 0, padding: '0,1rem,0,2rem'}} // Remove padding and margin
    >
      <Descriptions.Item label="ID">{site?.id}</Descriptions.Item>
      <Descriptions.Item label="Name">{site?.name}</Descriptions.Item>
      <Descriptions.Item label="Monitor">{site?.monitor ? 'Yes' : 'No'}</Descriptions.Item>
      <Descriptions.Item label="Grace Period">{site?.grace_period}</Descriptions.Item>
      <Descriptions.Item label="Latitude">{site?.latitude}</Descriptions.Item>
      <Descriptions.Item label="Longitude">{site?.longitude}</Descriptions.Item>
      <Descriptions.Item label="Timestamp">{moment(site?.timestamp).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Created">{moment(site?.created).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Updated">{moment(site?.updated).local().toISOString()}</Descriptions.Item>
    </Descriptions>
  </Card>
)

export default SiteDetails
