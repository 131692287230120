import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import PageHeader from '@components/page-header'
import {Wrapper} from '../styles'
import {Params, useFetchGet} from 'helpers/api'
import InfoRow from './info-row'
import ChartRow from './chart-row'
import './target.css'
import {Card, Spin} from 'antd'
import Details from './details'
import TargetAlerts from './target-alerts'

const LoadingState = () => (
  <div className="flex h-screen items-center justify-center">
    <Spin size="large" />
  </div>
)

const ErrorState = ({error}) => (
  <div className="flex h-screen items-center justify-center">
    <Card className="p-6 text-center">
      <h2 className="text-xl font-semibold text-red-600">Error</h2>
      <p className="mt-2">{error}</p>
    </Card>
  </div>
)

function getTitle(targetsview) {
  if (!targetsview) return ''
  const site = (
    <>
      <span className="text-gray-500">Site:</span> {targetsview.s_name}
    </>
  )
  const gateway = (
    <>
      <span className="text-gray-500">Gateway:</span> {targetsview.g_name}
    </>
  )
  if (targetsview.ca_name == targetsview.tg_name) {
    return (
      <>
        {site} {gateway} <span className="text-gray-500">Camera/Target:</span> {targetsview.ca_name}
      </>
    )
  } else {
    return (
      <>
        {site} {gateway} <span className="text-gray-500">Camera:</span> {targetsview.ca_name}{' '}
        <span className="text-gray-500">Target:</span> {targetsview.tg_name}
      </>
    )
  }
}

const TargetDashboard = () => {
  const {tgid} = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [targetsview, setTargetsview] = useState(null)
  const fetchTargetsview = useFetchGet('targetsview')

  const fetchTargetData = async () => {
    try {
      setIsLoading(true)
      setError(null)

      const params = Params({tg_id: tgid, limit: 1})
      await fetchTargetsview(params, response => {
        if (!response?.targetsview?.length) {
          throw new Error('Target not found')
        }
        setTargetsview(response.targetsview[0])
      })
    } catch (err) {
      setError(err.message || 'Failed to fetch gateway data')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (tgid) {
      fetchTargetData()
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgid])

  if (isLoading) {
    return <LoadingState />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!targetsview) {
    return <></>
  }

  return (
    <main className="min-h-screen bg-gray-50">
      <div style={{padding: '0 1rem'}}>
        <PageHeader ghost={false} onBack={() => window.history.back()} title={getTitle(targetsview)} />
      </div>

      <Wrapper>
        <InfoRow targetsview={targetsview} />
        <ChartRow targetsview={targetsview} />
        <TargetAlerts targetsview={targetsview} />
        <Details targetsview={targetsview} />
      </Wrapper>
    </main>
  )
}

export default TargetDashboard
