import {Card, Descriptions, Typography} from 'antd'
import moment from 'moment'

const {Title} = Typography

const TenantDetails = ({tenant}) => (
  <Card>
    <Title level={3}>Company</Title>
    <Descriptions
      column={1}
      bordered={true} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{fontWeight: 'bold', width: '15rem'}} // Adjust label width and style
      contentStyle={{margin: 0, padding: '0,1rem,0,2rem'}} // Remove padding and margin
    >
      <Descriptions.Item label="ID">{tenant?.id}</Descriptions.Item>
      <Descriptions.Item label="Name">{tenant?.name}</Descriptions.Item>
      <Descriptions.Item label="Created">{moment(tenant?.created).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Updated">{moment(tenant?.updated).local().toISOString()}</Descriptions.Item>
    </Descriptions>
  </Card>
)

export default TenantDetails
