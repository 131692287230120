export function deconstruct(targetsview) {
  const result = {
    tenant: {},
    site: {tid: targetsview.tid},
    gateway: {tid: targetsview.tid},
    camera: {tid: targetsview.tid},
    target: {tid: targetsview.tid}
  }

  Object.keys(targetsview).forEach(key => {
    const value = targetsview[key]
    if (key.startsWith('t_')) {
      result.tenant[key.slice(2)] = value // Remove 't_' prefix
    } else if (key.startsWith('s_')) {
      result.site[key.slice(2)] = value // Remove 's_' prefix
    } else if (key.startsWith('g_')) {
      result.gateway[key.slice(2)] = value // Remove 'g_' prefix
    } else if (key.startsWith('ca_')) {
      result.camera[key.slice(3)] = value // Remove 'ca_' prefix
    } else if (key.startsWith('tg_')) {
      result.target[key.slice(3)] = value // Remove 'tg_' prefix
    }
  })

  return result
}

export const stripPrefix = (obj, prefix) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      acc[key.slice(prefix.length)] = obj[key]
    }
    return acc
  }, {})
}

export function extractTenants(tenantsview) {
  return tenantsview.map(row => {
    const site = {}
    Object.keys(row).forEach(key => {
      const value = row[key]
      if (key.startsWith('t_')) {
        site[key.slice(2)] = value // Remove 't_' prefix
      } else {
        site[key] = value
      }
    })
    return site
  })
}

export function extractSites(sitesview) {
  return sitesview.map(row => {
    const site = {}
    Object.keys(row).forEach(key => {
      const value = row[key]
      if (key.startsWith('s_')) {
        site[key.slice(2)] = value // Remove 's_' prefix
      } else {
        site[key] = value
      }
    })
    return site
  })
}

export function extractGateways(gatewaysview) {
  return gatewaysview.map(row => {
    const gateway = {}
    Object.keys(row).forEach(key => {
      const value = row[key]
      if (key.startsWith('g_')) {
        gateway[key.slice(2)] = value // Remove 'g_' prefix
      } else {
        gateway[key] = value
      }
    })
    // Set location to RailGWStatus if Rail or to Site
    if (gateway.rgs_latitude && gateway.rgs_longitude) {
      gateway.latitude = gateway.rgs_latitude
      gateway.longitude = gateway.rgs_longitude
    } else {
      gateway.latitude = gateway.s_latitude
      gateway.longitude = gateway.s_longitude
    }
    return gateway
  })
}
