import React from 'react'
import ApexChart from 'react-apexcharts'
import {formatDateTime} from 'helpers/dates' // Import the helper function
import {colors} from 'helpers/style'

const TemperatureBars = ({targetsview}) => {
  // Extract the data for the chart
  const {tp_timestamp, tp_min, tp_max, tp_mean, tp_ext_temp} = targetsview

  // Define custom colors for each bar
  const barColors = [colors.chart.maximum, colors.chart.mean, colors.chart.minimum, colors.chart.ambient]

  // Chart configuration
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        distributed: false // Ensure colors are applied correctly
      }
    },
    dataLabels: {
      enabled: true, // Enable data labels
      style: {
        fontSize: '14px',
        colors: ['#FFFFFF'] // Set text color to white
      },
      offsetY: 0 // Adjust the position of the labels
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Max', 'Mean', 'Min', 'Ambient'],
      title: {
        text: undefined
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          color: 'black'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Temperature (°C)'
      },
      labels: {
        show: true // Hide y-axis labels
      }
    },
    fill: {
      opacity: 1
    },
    title: {
      text: `Temperature @  ${formatDateTime(tp_timestamp)}`, // Use the helper function to format the timestamp
      align: 'left',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: 'black'
      }
    },
    tooltip: {
      y: {
        formatter: val => `${val} °C` // Keep °C in tooltips
      }
    }
  }

  // Chart data series
  const chartSeries = [
    {
      name: 'Temperature',
      data: [
        {x: 'Max', y: tp_max, fillColor: barColors[0]},
        {x: 'Mean', y: tp_mean, fillColor: barColors[1]},
        {x: 'Min', y: tp_min, fillColor: barColors[2]},
        {x: 'Ambient', y: tp_ext_temp, fillColor: barColors[3]}
      ]
    }
  ]

  return (
    <div>
      <ApexChart options={chartOptions} series={chartSeries} type="bar" height={270} />
    </div>
  )
}

export default TemperatureBars
