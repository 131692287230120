import {Descriptions} from 'antd'
import {colors} from 'helpers/style'
import React from 'react'

const TemperatureDetails = ({temperature}) => {
  if (!temperature) return <></>

  // Parse the timestamp into date and time
  const timestamp = new Date(temperature.timestamp)
  const date = timestamp.toLocaleDateString() // Get date portion
  const time = timestamp.toLocaleTimeString() // Get time portion

  const barColors = [colors.chart.maximum, colors.chart.minimum, colors.chart.mean, colors.chart.ambient]

  return (
    <Descriptions
      column={1}
      bordered={false} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{
        fontWeight: 'bold', // Bold labels
        color: 'gray', // Black labels
        width: '5rem' // Adjust label width
      }}
      contentStyle={{
        fontWeight: 'bold', // Bold values
        color: 'black', // Black values
        margin: 0,
        padding: '0'
      }}
    >
      <Descriptions.Item label="Date">{date}</Descriptions.Item>
      <Descriptions.Item label="Time">{time}</Descriptions.Item>
      <Descriptions.Item label="Maximum">
        <span style={{color: colors.chart.maximum}}>{temperature.max}°C</span>
      </Descriptions.Item>
      <Descriptions.Item label="Mean">
        <span style={{color: colors.chart.mean}}>{temperature.mean}°C</span>
      </Descriptions.Item>
      <Descriptions.Item label="Minimum">
        <span style={{color: colors.chart.minimum}}>{temperature.min}°C</span>
      </Descriptions.Item>
      <Descriptions.Item label="Ambient">
        <span style={{color: colors.chart.ambient}}>{temperature.ambient}°C</span>
      </Descriptions.Item>
      <Descriptions.Item label="Humidity">{temperature.humidity}%</Descriptions.Item>
      <Descriptions.Item label="Pressure">{temperature.pressure} hPa</Descriptions.Item>
    </Descriptions>
  )
}

export default TemperatureDetails
