import {Card, Descriptions, Typography} from 'antd'
import moment from 'moment'

const {Title} = Typography

const TargetDetails = ({target}) => (
  <Card>
    <Title level={3}>Target</Title>
    <Descriptions
      column={1}
      bordered={true} // Remove borders
      size="small" // Use a smaller size for tighter spacing
      labelStyle={{fontWeight: 'bold', width: '15rem'}} // Adjust label width and style
      contentStyle={{margin: 0, padding: '0,1rem,0,2rem'}} // Remove padding and margin
    >
      <Descriptions.Item label="ID">{target?.id}</Descriptions.Item>
      <Descriptions.Item label="Name">{target?.name}</Descriptions.Item>
      <Descriptions.Item label="Warning Temperature Level">{target?.warning}</Descriptions.Item>
      <Descriptions.Item label="Critical Temperature Level">{target?.critical}</Descriptions.Item>
      <Descriptions.Item label="Created">{moment(target?.created).local().toISOString()}</Descriptions.Item>
      <Descriptions.Item label="Updated">{moment(target?.updated).local().toISOString()}</Descriptions.Item>
    </Descriptions>
  </Card>
)

export default TargetDetails
